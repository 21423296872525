:local {
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 100px 0 0 0;
    z-index: 11;
  }
  .transparent {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .opaque {
    background-color: white;
    border-radius: 8px;
  }

  .sticky {
    top: 30px + 216px + 100px;
    position: sticky;
  }

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
  }
}
