:local {
  .caption {
    // box-shadow:0 0 0 1px #e5e7eb;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px;
    // background-color: #e5e7eb;
    font-size: 14px;
    margin-bottom: -12px;
    // border-left: 1px solid #cbd5e1;
    z-index: 2;
    //position: sticky;
    //top: 128px;
    //z-index: 21;
    /*&:hover {
      background-color: #e5e7eb;
      box-shadow:0 0 0 2px #3b82f6;
    }*/
  }

  .block {
    box-shadow:0 0 0 1px #e5e7eb;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 40px;
    padding: 0 5px;
    font-size: 14px;
    /*&:hover {
      background-color: #e5e7eb;
      box-shadow:0 0 0 2px #3b82f6;
      z-index: 1;
    }*/
  }

  .nameCol {
    position: sticky;
    left: 0;
    z-index: 1;
    padding: 0;
    background-color: white;
  }
}