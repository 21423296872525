:local {
  .residence {
    font-size: 14px;
    color: #999999;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .alert {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .container {
    display: flex;
    min-height: 100vh;
    .left {
      flex: 0 0 348px;
      margin: 0 auto;
      .sticky {
        position: sticky;
        top: 0px;
        height: 100vh;
        display: flex;
        .center {
          box-shadow: 0 2px 15px -7px #000000;
          min-height: 300px;
          min-width: 300px;
          border-radius: 4px;
          margin: auto 24px;
          padding: 24px;
          border: 1px solid #ced4da;
        }
      }
    }
    .right {
      box-shadow: -10px -15px 9px -14px rgba(0,0,0,0.75);
      flex: 0 0 350px;
      padding: 80px 24px 24px 24px;

      .tile {
        width: 320px;
        margin: 10px 0;
        overflow: hidden;
        border: 1px solid #ced4da;

        .message {
          background-color: #d6554d;
          color: white;
        }
      }
    }
  }
}
