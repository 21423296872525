// Formatting of markdown elements
.markdown {}

// Additional formatting of markdown elements in the dialog
.dialog {
  a {
    color: #007bff;
    text-decoration: underline;
    &:hover {
      color: black;
      text-decoration: none;
    }
  }
}
