.card {
  position: relative;
  font-family: Arial, sans-serif;
  line-height: initial;
  width: 428px;
  height: 270px;
  background-color: white;
}

.screen {
  overflow: hidden;
  box-shadow: 2px 2px 15px #888888;
  &.front { margin: 15px auto 20px; }
  &.back { margin: 20px auto; }
}

.front {
  user-select: none;
  pointer-events: none;
}
.frontStripe {
  position: absolute;
  top: 85px;
  height: 100px;
  left: -5px;
  right: -5px;
}

.thumbnail {
  position: absolute;
  top: 33px;
  left: 20px;
  width: 158px;
  border: 0;
}

  .firstName {
    position: absolute;
    top: 97px;
    left: 198px;
    width: 230px;
    color: #ffffff;
    font-size: 35px;
    background-color: transparent;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .lastName {
    position: absolute;
    top: 141px;
    left: 198px;
    width: 230px;
    color: #ffffff;
    font-size: 25px;
    background-color: transparent;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .jobTitle {
    position: absolute;
    text-align: center;
    top: 205px;
    left: 188px;
    width: 230px;
    margin: 0 auto;
    font-size: 28px;
    font-weight: bolder;
    background-color: transparent;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


.back {
  .stripe {
    position:absolute;
    top:-5px;
    left:-5px;
    right: -5px;
    height:55px;
    text-align: center;
    padding: 15px 0 0 0;
    font-size: 25px;
    color: #ffffff;
  }

  .address {
    position: absolute;
    overflow: hidden;
    top: 64px;
    left: 15px;
    width: 200px;
    height: 95px;
    font-size: 15px;
    line-height: 19px;
    white-space: pre;
  }

  .barcode {
    position: absolute;
    overflow: hidden;
    top: 58px;
    left: 215px;
    width: 250px;
    height: 75px;
    color: black;
    line-height: initial;
    font-size: 40px;
    font-family: 'Barcode';
    transform: scale(1.2,1);
    transform-origin: top left;
    text-align: left;
  }

  .signatureEmployee {
    position: absolute;
    top: 155px;
    left: 15px;
    width: 175px;
    height: 150px;
    font-size: 10px;
  }

  .signatureEmployer {
    position: absolute;
    top: 155px;
    left: 225px;
    width: 250px;
    height: 75px;
    font-size: 10px;
  }

  .cardNumberCol1 {
    position: absolute;
    top: 205px;
    left: 15px;
    width: 195px;
    height: 45px;
    vertical-align: bottom;
    font-size: 10px;
  }

  .cardNumberCol2 {
    position: absolute;
    top: 205px;
    left: 225px;
    width: 195px;
    height: 45px;
    vertical-align: bottom;
    font-size: 10px;
  }

  .alignBottom {
    position: absolute;
    bottom: 0;
  }
}

:local {
  .tabs {
    flex: 1 1 auto;
    min-width: 580px;
    display: flex;
    flex-direction: column;
  }

  .spacer {
    flex: 0 0 24px;
  }

  @media (min-width: 1280px) {
    .tabs {
      flex: 0 0 908px; // Screen size 1280+
    }

    .spacer {
      flex: 1 1 auto;
    }
  }
}



