:local {
  @keyframes tada {
    from {
      transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
      transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    to {
      transform: scale3d(1, 1, 1);
    }
  }

  .overlay {
    z-index: 10000;
  }

  .tada {
    animation-name: tada;
    animation-duration: 1s;
    animation-fill-mode: both;
  }

  @keyframes flash {
    from,
    50%,
    to {
      opacity: 1;
    }

    25%,
    75% {
      opacity: 0.5;
    }
  }

  .flash {
    animation-name: flash;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }
}
