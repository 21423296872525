:local {
  .cornerBadge {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    width: 150px;
    height: 150px;
    > div {
      position: absolute;
      color: white;
      top: -100px;
      transform: rotate(45deg);
      right: -100px;
      width: 200px;
      height: 200px;
      padding-top: 155px;
      text-align: center;
      font-weight: bolder;
      font-size: 20px;
    }
  }
  .cornerBadgeSmall {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    width: 150px;
    height: 150px;
    > div {
      position: absolute;
      color: white;
      top: -120px;
      transform: rotate(45deg);
      right: -120px;
      width: 200px;
      height: 200px;
      padding-top: 160px;
      text-align: center;
      font-weight: bolder;
      font-size: 20px;
    }
  }
}
