:local {
  .page {
    position: relative;
    height: 839px; // A4 bei 40 ppcm
    width: 1188px; // A4 bei 40 ppcm
    background-color: white;
    page-break-after: always;
    overflow: hidden;
  }
}
