@import 'styles/helpers/media-high-resolution-only';

:local {
  .titleBarTab {
    height: 70px;
    padding: 32px 25px 0 25px;
    font-size: 1rem;
    font-weight: normal;
    color: gray; // // todo: find right gray $gray-darkest;
    white-space: nowrap;

    &:link,
    &:visited,
    &:active {
      color: gray; // // todo: find right gray $gray-darkest;
      text-decoration: none;
    }
    &:hover {
      color: black; // // todo: find right gray $gray-darkest;
      text-decoration: underline;
    }

    .tabLabel {
      display: inline-block;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.active {
      color: gray; // // todo: find right gray $gray-darkest;
      text-decoration: none;
      background-image: url('./images/titlebar-btn-left.png'),
        url('./images/titlebar-btn-right.png'), url('./images/titlebar-btn.png');
      background-size: 14px 70px, 14px 70px, 1px 70px;
      background-position: left top, right top, left top;
      background-repeat: no-repeat, no-repeat, repeat-x;

      @include media-high-resolution-only() {
        background-image: url('./images/titlebar-btn-left@2x.png'),
          url('./images/titlebar-btn-right@2x.png'),
          url('./images/titlebar-btn@2x.png');
      }
    }
  }
}
