:local {
  .menu {
    flex: 0 0 324px;
    position: relative;

    .caption {
      display: flex;
      position: absolute;
      left: 24px;
      top: 19px;
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
    }

    .member {
      position: relative;
      background: #eeeeee;
      border: 2px solid #eeeeee;
      border-radius: 4px;
      padding: 3px 8px;
      cursor: pointer;

      .name {
        display: flex;
      }
      .residence {
        font-size: 12px;
        color: #999999;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.active {
        border: 2px solid #538eff;
      }

      .showOnHover {
        display: none;
      }

      &:hover .showOnHover {
        display: initial;
      }
    }
  }
}
