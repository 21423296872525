:local {
  .empty {
    color: #dbddde;
  }

  .detail {
    padding: 0 20px 1px 80px;
    overflow: hidden;

    table {
      width: 100%;
      max-width: 100%;

      > tbody > tr:first-child > td {
        border: none;
      }
    }
  }

  .detailField {
    width: 26%;
    @apply border-b;
    @apply border-gray-200;
  }

  .detailFrom {
    width: 30%;
    @apply border-b;
    @apply border-gray-200;
  }

  .detailArrow {
    width: 14%;
  }

  .detailTo {
    width: 30%;
    @apply border-b;
    @apply border-gray-200;
  }

  .strikeThrough {
    text-decoration: line-through;
    text-decoration-color: rgba(0, 0, 0, 0.42);
  }
}
