:local {
  .page {
    position: relative;
    width: 840px; // A4 bei 40 ppcm
    min-height: 1188px; // A4 bei 40 ppcm
    background-color: white;
    page-break-after: always;
    padding: 50px 100px 100px 100px;
    font-size: 13px;
  }

  .logo {
    height: 63px;
    margin-bottom: 10px;
  }
}
