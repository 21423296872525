:local {
  .A4 {
    position: relative;
    width: 840px;
    height: 1188px;
    overflow: hidden;
    background-color: white;
    font-family: "Exo 2", sans-serif;
  }

  .row {
    display: flex;
    line-height: 30px;
    margin: 0 32px;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    &:first-child {
      border-top: 1px solid black;
    }
  }
  .rowKey {
    flex: 0 0 200px;
    font-weight: 700;
    padding-left: 8px;
  }
  .rowValue {
    flex: 1 1 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 544px;
    padding-right: 8px;
  }

  .colDate {
    flex: 0 0 140px;
    padding-left: 8px;
  }
  .colLabel {
    flex: 1 1 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .colBreak {
    flex: 0 0 100px;
    text-align: right;
  }
  .colHours {
    flex: 0 0 108px;
    text-align: right;
    padding-right: 8px;
  }

  .signature {
    padding: 40px 40px 0 40px;
    line-height: 30px;
  }
}
