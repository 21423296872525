@import 'styles/variables';

:local {
  .forbidden {
    position: absolute;
    top: $navbar-height;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #dc3545;
    color: white;
    display: flex;
    flex-direction: column;
  }
}
