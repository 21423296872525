:local {
  .page {
    position: relative;
    width: 342px; // 8,56cm bei 40ppcm
    height: 214px; // 5.398cm bei 40ppcm
    background-color: white;
    overflow: hidden;
    break-inside: avoid;
  }
}
