:local {
  .container {
    height: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .progress {
    height: 100%;
    width: 100%;
    transform: scaleX(0);
    transform-origin: left;
    transition: 1s transform ease;
  }

  .fadeOut {
    opacity: 0;
    transition: 2s opacity ease;
  }
}
