$handleSize: 10px;
$handleDistance: -5px;

.movable {
  pointer-events: all;
  position: absolute;
  cursor: move;
  border: none;

  &:hover .edge {
    background-color: rgb(158, 158, 158);
  }

  &:focus-within {
    .edge {
      background-color: rgb(83, 142, 255) !important;
    }
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border: none;
    pointer-events: none;
  }

  .edge {
    position: absolute;
    width: $handleSize;
    height: $handleSize;
    border-radius: 0.5 * $handleSize;

    &.topLeft {
      cursor: nwse-resize;
      top: -($handleSize + $handleDistance);
      left: -($handleSize + $handleDistance);
    }
    &.topRight {
      cursor: nesw-resize;
      top: -($handleSize + $handleDistance);
      right: -($handleSize + $handleDistance);
    }
    &.bottomRight {
      cursor: nwse-resize;
      bottom: -($handleSize + $handleDistance);
      right: -($handleSize + $handleDistance);
    }
    &.bottomLeft {
      cursor: nesw-resize;
      bottom: -($handleSize + $handleDistance);
      left: -($handleSize + $handleDistance);
    }
  }
}

.toggle {
  position: absolute;
  top: -13px;
  right: -13px;
  width: 40px;
  height: 40px;
  background: white;
  color: #a2a7ab;
  border-radius: 50%;
  text-align: center;
  line-height: 38px;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.16);
  transition: all 400ms ease;

  display: block;
  padding: 0;
  border: none;
  &:focus {
    box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.16);
  }
  &:hover {
    background: #538eff;
    color: white;
  }
}
