:local {
  .drop {
    border: 2px dashed #d2d2e8;
    margin: 10px 0 0 10px;
    width: 50px;
    height: 50px;
    border-radius: 6px;
  }

  .documentName {
    flex: 0 1 auto;
    margin: 13px 15px 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .documentDetails {
    margin: 0 15px 0 5px;
  }
}
