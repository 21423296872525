:local {
  .A4 {
    position: relative;
    width: 840px;
    min-height: 1188px;
    overflow: hidden;
    background-color: white;
    padding: 2.2 * 40px 2.2 * 40px 40px 2.2 * 40px; // 2.2 cm padding
    font-family: "Exo 2", sans-serif;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
    td {
      padding: 5px;
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    color: #fff;
    background-color: #d6554d;
    margin-right: 10px;
  }
}
