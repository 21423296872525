:local {
  .page {
    position: relative;
    width: 438px;
    height: 279px;
    padding: 5px; // Beschnittzugabe
    overflow: hidden;
    background-color: white;
    page-break-after: always;
  }
}
