.tooltip { 
  @apply invisible absolute;
  pointer-events: none;
  background: #1f2a37;
  color: #ffffff;
  text-align: center;
  padding: 8px 10px;
  border-radius: 3px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 50;
  &.top {
    bottom: calc(100% + 11px);
    left: 50%;
    transform: translate(-50%, 0);
  }
  &.left {
    top: 50%;
    left: inherit;
    transform: translate(0, -50%);
    right: calc(100% + 11px);
  }
  &.right {
    top: 50%;
    left: calc(100% + 11px);
    transform: translate(0, -50%);
  }
  &.bottom {
    top: calc(100% + 11px);
    left: 50%;
    transform: translate(-50%, 0);
  }
}

:global(.has-tooltip) { 
  position: relative;
  &:hover .tooltip {
    @apply visible z-50;
  }
  &:hover .triangle {
    @apply visible z-50;
  }
}

.triangle { 
  @apply invisible absolute;
  pointer-events: none;
  border-color: transparent;
  border-width: 0 6px 6px;
  border-bottom-color: #1f2a37;
  
  &.top {
    bottom: calc(100% + 5px);
    transform: rotate(180deg) translate(-50%, 0);
    left: calc(50% - 12px);
  }

  &.left {
    left: -18px;
    top: calc(50% - 3px);
    transform: rotate(90deg) translate(0, -50%);
  }

  &.right {
    right: -17px;
    top: calc(50% - 3px);
    transform: rotate(270deg) translate(0, -50%);
  }

  &.bottom {
    bottom: -11px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}