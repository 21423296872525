:local {
  .container {
    position: relative;
    display: inline-block; // prevents margin collaps of children with parents
    //margin-right: 22px;
  }

  .page {
    background-color: white;
    overflow: hidden; // Text layer is sometimes too big
    direction: ltr; // Taken from pdfjs-dist/web/pdf_viewer.css
    margin: 0 0 12px 0;
    box-shadow: 0 2px 5px 0 rgba(222, 222, 222, 0.5); // taken from bootstrap .card
  }

  .empty {
    width: 840px;
    height: 1188px; // 840 * sqrt(2)
  }

  .spinner {
    width: 100px;
    height: 100px;
    position: relative;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
