:local {
  .page {
    position: relative;
    width: 840px; // A4 bei 40 ppcm
    min-height: 1188px; // A4 bei 40 ppcm
    background-color: white;
    page-break-after: always;
    padding: 60px 100px 100px 100px;
    font-size: 13px;
  }

  .logo {
    margin: 8px;
    width: 181px;
  }
}
