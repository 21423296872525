:local {
  .page {
    position: relative;
    width: 348px; // 8.7cm bei 40ppcm
    height: 222px; // 222px + 2px rounding issues = 224px = 5.6cm bei 40ppcm
    background-color: white;
    break-inside: avoid;
    overflow: hidden;
  }
}
