:local {
  .page {
    position: relative;
    width: 356px; // 8,9cm bei 40ppcm
    height: 226px; // 5.65cm bei 40ppcm
    background-color: white;
    border: dashed 1px #000;
    border-radius: 1px;
    break-inside: avoid;
    overflow: hidden;
  }
}
