:local {
  .dropzone {
    width: 464px;
    padding: 40px 40px 16px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    flex: 1 1 auto;

    &.page {
      box-shadow: 0 2px 5px 0 rgba(222, 222, 222, 0.5);
      height: 1188px;
      background-color: white;
    }
  }

  .canAccept {
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: transform .25s ease-in-out;
  }

  .accept {
    transform: scale(1.2);
    border-color: rgb(34 197 94);
  }

  .pulse {
    position: relative;
    // height: 18px;
    // width: 18px;
    // border-radius: 50%;

    &:before {
      position: absolute;
      content: "";
      height: 62px;
      width: 62px;
      left: 50%;
      top: 50%;
      background-color: transparent;
      border-radius: 50%;
      border: 1px solid #6366f1;
      // box-shadow: 0px 0px 2px 2px $gray-light;
      animation: pulse 1s infinite linear;
    }
  }

  @keyframes pulse {
    0%{
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    100%{
      transform: translate(-50%, -50%) scale(1.5);
      opacity: 0;
    }
  }
}

