@import 'styles/helpers/media-high-resolution-only';
@import 'styles/variables';

:local {
  .titleBar {
    display: flex;
    height: 70px;
    overflow: hidden;
    background: gray url('./images/titlebar-bg.png') repeat-x; // todo: find right gray-lightest
    background-size: 1px 70px;
    z-index: 11;

    @include media-high-resolution-only() {
      background-image: url('./images/titlebar-bg@2x.png');
    }
  }
}
