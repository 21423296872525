:local {
  .page {
    position: relative;
    width: 840px; // A4 bei 40 ppcm
    background-color: white;
    page-break-after: always;
    font-size: 16px;
    text-align: center;
  }
}
