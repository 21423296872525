:local {
  .portrait {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
    position: absolute;
    top: 34px;
    z-index: 10;
    right: 20px;
    transform: rotate(3deg);
    -webkit-backface-visibility: hidden;
    width: 212px;
  }
}
