:local {
  .circularProgressbar {
    /*
    * This fixes an issue where the CircularProgressbar svg has
    * 0 width inside a "display: flex" container, and thus not visible.
    *
    * If you're not using "display: flex", you can remove this style.
    */
    width: 100%;

    .circularProgressbarPath {
      stroke: #6366f1;
      stroke-linecap: round;
      transition: stroke-dashoffset 0.5s ease 0s;
    }

    .circularProgressbarTrail {
      stroke: #f3f4f6;
    }

    .animated {
      transform-origin: 50px 50px 0;
      transform: translateZ(0);
      animation: loaderSpin 2s linear infinite;
    }
  }

  @keyframes loaderSpin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
