:local {
  .page {
    position: relative;
    width: 1188px; // A4 bei 40 ppcm
    height: 839px; // A4 bei 40 ppcm
    background-color: white;
    page-break-after: always;
    padding: 100px 60px 40px 60px;
  }

  .logo {
    position: absolute;
    top: 40px;
    right: 60px;
    height: 63px;
  }
}
