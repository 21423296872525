/* libre-barcode-128-regular - latin */
@font-face {
  font-family: 'Barcode';
  font-style: normal;
  font-weight: 400;
  src: url('./libreBarcode/libre-barcode-128-v26-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('LibreBarcode128-Regular'),
       url('./libreBarcode/libre-barcode-128-v26-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./libreBarcode/libre-barcode-128-v26-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./libreBarcode/libre-barcode-128-v26-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./libreBarcode/libre-barcode-128-v26-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./libreBarcode/libre-barcode-128-v26-latin-regular.svg#LibreBarcode128') format('svg'); /* Legacy iOS */
}
