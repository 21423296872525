:local {
  .tabs {
    flex: 1 1 auto;
    min-width: 580px;
    display: flex;
    flex-direction: column;
  }

  .spacer {
    flex: 0 0 24px;
  }

  @media (min-width: 1280px) {
    .tabs {
      flex: 0 0 908px; // Screen size 1280+
    }

    .spacer {
      flex: 1 1 auto;
    }
  }
}
