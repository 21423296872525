:local {
  .page {
    position: relative;
    width: 840px; // A4 bei 40 ppcm
    min-height: 1188px; // A4 bei 40 ppcm
    background-color: white;
    page-break-after: always;
    padding: 120px 100px 100px 100px;
    font-size: 13px;
  }

  .logo {
    position: absolute;
    top: 40px;
    right: 100px;
    height: 63px;
  }

  .checkboxLabel {
    font-size: 14px !important;
    padding-left: 0 !important;
  }
}
