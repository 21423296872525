:local {
  .page {
    position: relative;
    width: 542px; // 13.55cm bei 40ppcm
    height: 366px; // 370 - 4px rounding issues. 370 = 9.25cm bei 40ppcm
    background-color: white;
    break-inside: avoid;
    overflow: hidden;
  }
}
