:local {
  .round {
    width: 30px;
    height: 30px;
    padding: 0;
    /*position: absolute;
    border: none;
    border-radius: 50%;
    text-align: center;
    line-height: 28px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.16);
    transition: all 400ms ease;*/
  }
}
